import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { mediaQueries } from "./breakpoints"
import { Container } from "./Container"
import { css } from "@emotion/react"
import { Section } from "./Section"
import iconHomepage from "../images/homepage.svg"
import iconFacebook from "../images/facebook.svg"
import iconTwitter from "../images/twitter.svg"
import iconLinkedin from "../images/linkedin.svg"
import { Modal } from "./Modal"
import CTA from "./CTA"
import Marked from "react-markdown"

const Speakers = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: -1rem;
  margin-right: -1rem;
  ${mediaQueries.medium(
    css`
      margin: 0;
    `
  )}
  ${mediaQueries.large(
    css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `
  )}
`

const Speaker = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  &::before {
    content: "";
    display: table;
    padding-top: 100%;
  }
  ${mediaQueries.largeDown(
    css`
      &:nth-child(4n+1),
      &:nth-child(4n+4),
      &:nth-child(4n){
        background-color: #312E2E;
      }
    `
  )}
  ${mediaQueries.large(
    css`
      &:nth-child(8n+1),
      &:nth-child(8n+3),
      &:nth-child(8n+6),
      &:nth-child(8n){
        background-color: #312E2E;
      }
    `
  )}
`

const SpeakerContent = styled("div")`
  width: 100%;
  padding: 2rem;
  ${mediaQueries.smallDown(
    css`
      padding: 1.5rem 1rem;
    `
  )}
`

const SpeakerName = styled("h3")`
  color: var(--yellow);
  ${mediaQueries.mediumDown(
    css`
      font-size: 1.3rem;
    `
  )}
  ${mediaQueries.smallDown(
    css`
      font-size: 1.2rem;
    `
  )}
`

const SpeakerPosition = styled("h4")`
  font-weight: 400;
  ${mediaQueries.mediumDown(
    css`
      font-size: 1rem;
    `
  )}
`

const SpeakerIcons = styled("div")`
  margin-top: 1rem;
  &:empty {
    display: none;
  }
`

const SpeakerIconsModal = styled(SpeakerIcons)`
  margin-left: -.5rem;
`

const SpeakerIcon = styled("div")`
  display: inline-block;
  width: 2rem;
  text-align: center;

  a, button {
    width: 100%;
    display: inline-block;
    transform: scale(1);
    transition: all .1s ease-in-out;
    @media (hover: hover) {
      &:hover {
        transform: scale(1.15);
        filter: drop-shadow(0 0 2px var(--yellow));
      }
    }
  }
`

const NoSpeakers = styled("p")`
  text-align: center;
`

const SectionCTA = styled(Section)`
  text-align: center;
`

class EventSpeakers extends React.Component {

  render() {
    const { data } = this.props
    const { edges: events } = data.allMarkdownRemark

    return (
      <>
        {events && events.length ? (
          <div>
            <Section id="prednasejici">
              <Container >
                <h2>PŘEDNÁŠEJÍCÍ</h2>
                {events &&
                  events.map(({ node: event }) => (
                    <div key={event.id}>
                      {event.frontmatter.speakers &&
                      event.frontmatter.speakers.length ? (
                        <Speakers>
                          {event.frontmatter.speakers.map(speaker => (
                            <Speaker>
                              <SpeakerContent key={speaker.id}>
                                {speaker.speakerName && (
                                  <SpeakerName>{speaker.speakerName}</SpeakerName>
                                )}
                                {speaker.speakerPosition && (
                                  <SpeakerPosition>{speaker.speakerPosition}</SpeakerPosition>
                                )}
                                <SpeakerIcons>
                                  {speaker.speakerInfo && (
                                    <SpeakerIcon>
                                      <Modal>
                                        <SpeakerName>{speaker.speakerName}</SpeakerName>
                                        {speaker.speakerPosition && (
                                          <SpeakerPosition>{speaker.speakerPosition}</SpeakerPosition>
                                        )}
                                        <SpeakerIconsModal>
                                          {speaker.speakerHomepage && (
                                            <SpeakerIcon>
                                              <a target="blank" rel="noopener noreferrer" href={speaker.speakerHomepage}>
                                                <img src={iconHomepage} />
                                              </a>
                                            </SpeakerIcon>
                                          )}
                                          {speaker.speakerFacebook && (
                                            <SpeakerIcon>
                                              <a target="blank" rel="noopener noreferrer" href={speaker.speakerFacebook}>
                                                <img src={iconFacebook} />
                                              </a>
                                            </SpeakerIcon>
                                          )}
                                          {speaker.speakerTwitter && (
                                            <SpeakerIcon>
                                              <a target="blank" rel="noopener noreferrer" href={speaker.speakerTwitter}>
                                                <img src={iconTwitter} />
                                              </a>
                                            </SpeakerIcon>
                                          )}
                                        </SpeakerIconsModal>
                                        <Marked source={speaker.speakerInfo} />
                                      </Modal>
                                    </SpeakerIcon>
                                  )}
                                  {speaker.speakerHomepage && (
                                    <SpeakerIcon>
                                      <a target="blank" rel="noopener noreferrer" href={speaker.speakerHomepage}>
                                        <img src={iconHomepage} />
                                      </a>
                                    </SpeakerIcon>
                                  )}
                                  {speaker.speakerFacebook && (
                                    <SpeakerIcon>
                                      <a target="blank" rel="noopener noreferrer" href={speaker.speakerFacebook}>
                                        <img src={iconFacebook} />
                                      </a>
                                    </SpeakerIcon>
                                  )}
                                  {speaker.speakerTwitter && (
                                    <SpeakerIcon>
                                      <a target="blank" rel="noopener noreferrer" href={speaker.speakerTwitter}>
                                        <img src={iconTwitter} />
                                      </a>
                                    </SpeakerIcon>
                                  )}
                                  {speaker.speakerLinkedin && (
                                    <SpeakerIcon>
                                      <a target="blank" rel="noopener noreferrer" href={speaker.speakerLinkedin}>
                                        <img src={iconLinkedin} />
                                      </a>
                                    </SpeakerIcon>
                                  )}
                                </SpeakerIcons>
                              </SpeakerContent>
                            </Speaker>
                          ))}
                        </Speakers>
                      ) : <NoSpeakers>Již brzy představíme první přednášející.</NoSpeakers> }
                    </div>
                  )
                )}
              </Container>
            </Section>
            <>
              {events &&
                events.map(({ node: event }) => (
                  <div key={event.id}>
                    {event.frontmatter.speakers &&
                    event.frontmatter.speakers.length ? (
                      <SectionCTA>
                        <Container>
                          <h2>TAKOVÁ SESTAVA STOJÍ ZA&nbsp;TO</h2>
                          <CTA />
                        </Container>
                      </SectionCTA>
                    ) :
                      ( null )
                    }
                  </div>
                )
              )}
            </>
          </div>
          ) : null }
      </>
    )
  }
}

EventSpeakers.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query EventSpeakersQuery {
        allMarkdownRemark(
          limit: 1
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              registrationStatus: { in: [ "preparation", "open", "close", "postponed" ]}
              templateKey: { eq: "event" }
            }
          }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                templateKey
                speakers {
                  speakerName
                  speakerPosition
                  speakerInfo
                  speakerHomepage
                  speakerFacebook
                  speakerTwitter
                  speakerLinkedin
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <EventSpeakers data={data} count={count} />}
  />
)
